<template>
  <v-main>
    <v-container fluid fill-height>
      <v-row class="justify-center align-center">
        <v-col justify-center :cols="6">
          <v-card class="elevation-0" id="no-account-card">
            <v-card-title class="error--text">Missing account activation</v-card-title>
            <v-card-text>
              <p class="body-1">
                Sorry, it appears you have not activated the Vcad App for Autodesk Construction Cloud<span>&#174;</span>.
              </p>
              <p class="body-1">
                <b>To use Vcad you must have activated the Vcad App in the <i>Account Admin</i> section of your Autodesk Construction Cloud<span>&#174;</span> instance.</b> <br/>To do so you must have Admin privileges, if you do not, please contact your account admin.
                <br/>You can find more information on activating a trial account <a href="https://www.bimservices.it/authorize-autodesk-bim-360-to-use-the-vcad-app/" target="_blank">here</a>.
              </p>

              <p class="body-1">If you have completed your trial period and wish to purchaise a full license please <a href="https://www.bimservices.it/vcad-demo-request/" target="_blank">contact us</a>.</p>
              <p class="caption mt-10">If you believe you should be able to use Vcad and there has been an error, please contact our customer service at: <a href="mailto:support@vcad.it">support@vcad.it</a></p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
  #no-account-card{
    border:solid 1px rgba(0,0,0,0.38);
  }
</style>